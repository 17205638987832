<template>
    <div class="box">
        <div class="box-header">
            <h4>{{ pageTitle }} - {{formModel?formModel.product_name:''}}</h4>
        </div>
        <el-form
            v-if="formModel"
            :rules="formRules"
            :model="formModel"
            ref="formModel"
            label-width="100px"
            label-suffix="："
            @submit.native.stop.prevent="handleFormSubmit('formModel')">
            <el-form-item
            >
            <el-col :md="9">
              <el-alert title="修改此处价格将影响已勾选的全部店铺" type="info" show-icon></el-alert>
              </el-col>
            </el-form-item>
            <el-form-item
                label="价格"
                prop="price"
            >
                <el-col :md="5">
                    <el-input
                        type="text"
                        v-model.trim="formModel.price"
                    ></el-input>
                </el-col>
            </el-form-item>
            <el-form-item
                label="灰色价格"
                prop="false_price"
            >
                <el-col :md="5">
                    <el-input
                        type="text"
                        v-model.trim="formModel.false_price"
                    ></el-input>
                </el-col>
            </el-form-item>

            <el-form-item label="配置店铺">
                <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                >全选</el-checkbox>
                <el-checkbox-group
                    v-model="branchShopIds"
                    @change="handleCheckedCitiesChange"
                >
                    <el-checkbox
                        v-for="(item,index) in stores"
                        :key="index"
                        :label="item.id"
                    >{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="是否上架">
                <el-switch
                  v-model="formModel.is_have"
                  :active-text="formModel.is_have == 1?'是':'否'"
                >
                </el-switch>
            </el-form-item>

            <el-form-item label="是否外卖">
                <el-switch
                  v-model="formModel.is_wm"
                  :active-text="formModel.is_wm == 1?'是':'否'"
                >
                </el-switch>
            </el-form-item>

            <!-- <el-form-item
                label="库存"
                prop="stock"
            >
                <el-button
                    size='mini'
                    @click="openStock"
                >
                    {{(formModel.is_ks_stock === '1')?'不计库存':formModel.stock}}
                </el-button>
            </el-form-item> -->

            <el-form-item>
                <el-button
                    size="medium"
                    type="primary"
                    native-type="submit"
                    :loading="submitLoading"
                >确定</el-button>
            </el-form-item>
            <!-- 弹出窗口内容 -->
            <el-dialog
                :visible.sync="dialogVisible"
                width="400"
            >
                <div class="stock-box">
                    <div class="stock-item">
                        当前库存
                        <el-input
                            type="number"
                            :min="0"
                            v-model.trim="stock.stock"
                        />
                    </div>
                    <div class="stock-item">
                        每日最大数量
                        <el-input
                            type="number"
                            :min="0"
                            v-model.trim="stock.max_stock"
                        />
                    </div>
                    <div class="stock-item">
                        <el-checkbox v-model="stock.is_refresh">每日刷新重置数量</el-checkbox>
                    </div>
                    <div class="stock-item">
                        <el-checkbox v-model="stock.is_ks_stock">不计库存</el-checkbox>
                    </div>
                    <el-button
                     v-if="$can('admin/shop/update-temp')"
                    @click="stockSave()">确定</el-button>
                </div>
            </el-dialog>
        </el-form>
        <placeholder-form v-else></placeholder-form>
    </div>
</template>

<script>
import ShopTempService from '@admin/services/ShopTempService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  name: 'ShopTempPz',
  data () {
    return {
      isIndeterminate: false,
      checkAll: false,
      checkList: [],
      branchShopIds: [],
      pageTitle: '素材配置',
      formModel: null,
      dialogVisible: false,
      submitLoading: false,
      formRules: {
        price: [
          {
            required: true,
            message: '请输入商品金额',
            trigger: 'blur'
          }
        ]
      },
      stock: {
        stock: 0,
        max_stock: 0,
        is_refresh: false,
        is_ks_stock: true
      }
    }
  },

  components: {
    PlaceholderForm
  },
  async mounted () {
    const { data } = await flatry(
      ShopTempService.peiZhi(this.$router.currentRoute.query.id)
    )
    if (data) {
      this.formModel = data.data.shop
      this.formModel.is_ks_stock = '1'
      this.stores = data.data.stores
    } else {
      this.formModel.product_name = ''
    }
  },

  methods: {
    handleCheckAllChange (val) {
      if (val) {
        let arr = []
        this.stores.forEach(element => {
          arr.push(element.id)
        })
        this.branchShopIds = arr
      } else {
        this.branchShopIds = []
      }
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      this.checkList = value
      let checkedCount = value.length
      this.checkAll = checkedCount === this.stores.length
      this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.stores.length
    },
    // 打开选择库存model
    openStock () {
      let stock = Object.assign({
        stock: this.formModel.stock,
        max_stock: this.formModel.max_stock,
        is_refresh: this.formModel.is_refresh === '1',
        is_ks_stock: this.formModel.is_ks_stock === '1'
      })
      this.stock = stock
      this.dialogVisible = true
    },
    //  保存库存
    stockSave () {
      this.formModel.stock = this.stock.stock
      this.formModel.max_stock = this.stock.max_stock
      this.formModel.is_refresh =
                this.stock.is_refresh === true ? '1' : '0'
      this.formModel.is_ks_stock =
                this.stock.is_ks_stock === true ? '1' : '0'
      this.dialogVisible = false
    },
    async handleFormSubmit (formName) {
      if (Number(this.formModel.price) < 0) {
        this.$message.warning('价格不能小于0')
        return false
      }

      if (this.branchShopIds.length <= 0) {
        this.$message.warning('请选择店铺')
        return
      }
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }
      })

      this.formModel.stores = this.branchShopIds
      const { data } = await flatry(ShopTempService.peiZhi(this.$router.currentRoute.query.id, this.formModel))
      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/shop-temp' })
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.stock-box {
    padding: 0 40px;
    .stock-item {
        margin-bottom: 20px;
    }
}
</style>
